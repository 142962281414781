import Image from '@next/image';
import classNames from 'classnames';

import classes from './ContentBox.module.scss';
import Props from './ContentBox.types';

const ContentBox = ({
  children,
  variant,
  className,
  alt,
  resolveConfig,
  objectFit = 'cover',
  hasLazyLoad = true,
  hasPreload,
  style,
  wide,
}: Props): JSX.Element => (
  <div
    className={classNames(className, classes.wrapper, !!variant && classes[variant], { [classes.wide]: wide })}
    style={style}
  >
    {alt && resolveConfig && (
      <Image
        layout="fill"
        objectFit={objectFit}
        alt={alt}
        resolveConfig={resolveConfig}
        hasLazyLoad={hasLazyLoad}
        hasPreload={hasPreload}
      />
    )}
    {children}
  </div>
);

export default ContentBox;
